<template>
  <div class="w-full bg-offwhite flex py-16 mobile:px-4">
    <div class="flex w-3/4 mx-auto px-16 mobile:w-full mobile:px-0">
      <div class="w-1/2 flex flex-col mobile:w-full ">
        <span class="text-5xl font-extrabold mobile:text-center"
          >Lihtsaim tööriist turul.</span
        >
        <span
          class="text-5xl font-extrabold mb-4 mobile:text-3xl mobile:text-center"
          ><span class="text-green">Garanteeritult</span>.</span
        >
        <span class="text-2xl mb-4 text-darkgrey"
          >Gewodo on ideaalne igapäevane tööriist väikeettevõtjale töö, müügi ja
          töötajate haldamiseks. Soodsalt. Sa ei vaja midagi muud. Selles oleme
          me kindlad.</span
        >
        <span
          class="mb-2 text-xs text-darkgrey mobile:w-full mobile:self-center"
          >Proovi Gewodo tööhaldust TASUTA. Ei mingit kaardi lisamist. Õpitav 15
          minutiga.</span
        >

        <router-link
          :to="$store.state.auth.status.loggedIn ? '/calendar' : '/register'"
          class="mr-3 mobile:mr-0 mobile:self-center w-full mobile:mb-2"
        >
          <button
            class="bg-turquoise hover:bg-turquoisedark hover:shadow-turquoiseoutline border-2 border-turquoise text-white font-semibold text-xl py-2 px-4 w-full"
          >
            Proovi TASUTA
          </button></router-link
        >
        <span class="mb-2 text-xs mobile:self-center"
          >* proovi 14 päeva tasuta Premium paketti. Baaspakett ALATI tasuta.
          Vaata hinnastamist SIIT.</span
        >
      </div>
      <div class="w-1/2 flex justify-center items-center mobile:hidden">
        <img src="/header/product.png" class="rounded-xl" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: ""
    };
  }
};
</script>
