<template>
  <div class="flex flex-col py-16 bg-offwhite">
    <span
      class="text-5xl self-center font-semibold mb-4 mobile:text-center mobile:text-4xl"
      >Läbimõeldud tööprotsessid</span
    >
    <span
      class="text-3xl mobile:text-2xl self-center font-medium mb-16 mobile:text-center"
      >Kõik, mida paremaks tööks vajad</span
    >
    <div class="flex mb-16 mobile:flex-col">
      <div
        class="flex w-1/4 border-r-4 justify-center items-center mobile:w-full mobile:border-none mobile:mb-4"
        :class="[
          activeView === 1 ? 'text-green' : 'cursor-pointer hover:text-green',
          [1, 2].includes(activeView) ? 'border-green' : 'border-black'
        ]"
        @click="activateView(1)"
      >
        <img
          src="/icons/hdil/pm.svg"
          class="mr-1 h-8 w-8"
          :class="activeView === 1 ? 'filter-to-green' : ''"
        />
        <span class="hdil-section-title ml-1">Projektihaldus</span>
      </div>
      <div
        class="flex w-1/4 border-r-4 justify-center items-center mobile:w-full mobile:border-none mobile:mb-4"
        :class="[
          activeView === 2 ? 'text-green' : 'cursor-pointer hover:text-green',
          [2, 3].includes(activeView) ? 'border-green' : 'border-black'
        ]"
        @click="activateView(2)"
      >
        <img
          src="/icons/hdil/tm.svg"
          class="mr-1 h-8 w-8"
          :class="activeView === 2 ? 'filter-to-green' : ''"
        />
        <span class="hdil-section-title ml-1">Meeskonnahaldus</span>
      </div>
      <div
        class="flex w-1/4 border-r-4 justify-center items-center mobile:w-full mobile:border-none mobile:mb-4"
        :class="[
          activeView === 3 ? 'text-green' : 'cursor-pointer hover:text-green',
          [3, 4].includes(activeView) ? 'border-green' : 'border-black'
        ]"
        @click="activateView(3)"
      >
        <img
          src="/icons/hdil/fm.svg"
          class="mr-1 h-8 w-8"
          :class="activeView === 3 ? 'filter-to-green' : ''"
        />
        <span class="hdil-section-title ml-1">Arved ja pakkumised</span>
      </div>
      <div
        class="flex w-1/4 justify-center items-center mobile:w-full mobile:border-none mobile:mb-4"
        :class="[
          activeView === 4 ? 'text-green' : 'cursor-pointer hover:text-green'
        ]"
        @click="activateView(4)"
      >
        <img
          src="/icons/hdil/cm.svg"
          class="mr-1 h-8 w-8"
          :class="activeView === 4 ? 'filter-to-green' : ''"
        />
        <span class="hdil-section-title ml-1">Kliendihaldus</span>
      </div>
    </div>
    <div class="flex px-12 mobile:px-4 mobile:flex-col" v-if="activeView === 1">
      <div
        class="w-3/12 px-8 mobile:px-0 items-center flex mobile:flex-col mobile:w-full"
      >
        <div class="hdil-list">
          <hdil-card
            @activateItem="activateItem(1)"
            header="Halda projekte"
            text="Vii kõik oma ettevõtte projektid ühte kohta. Jälgi igat aspekti projektist mugavalt ühest vaatest."
            icon="/icons/hdil/pm-mp.svg"
            :active="activeView === 1 && activeItem === 1"
          ></hdil-card>
          <hdil-card
            @activateItem="activateItem(2)"
            header="Jälgi tööd kalendrist"
            text="Järgi oma ülesandeid kuu, nädala või päeva vaates. Vaata kas kõiki ülesandeid korraga või töötajapõhiselt."
            icon="/icons/hdil/pm-cal.svg"
            :active="activeView === 1 && activeItem === 2"
          ></hdil-card>
          <hdil-card
            @activateItem="activateItem(3)"
            header="Lisa ülesandeid"
            text="Sa saad mugavalt lisada sobivatele töötajatele ja ajale tööülesandeid."
            icon="/icons/hdil/pm-at.svg"
            :active="activeView === 1 && activeItem === 3"
          ></hdil-card>
          <hdil-card
            @activateItem="activateItem(4)"
            header="Määra korduvaid ülesandeid"
            text="Sa saad määrata ülesande korduvaks sulle sobivas ajavahemikus. Ei mingit topeltsisestust. "
            icon="/icons/hdil/pm-addrep.svg"
            :active="activeView === 1 && activeItem === 4"
          ></hdil-card>
        </div>
      </div>
      <div
        class="w-6/12 justify-center flex px-16 mobile:hidden medium:px-4 hdil-feature-img-container"
      >
        <img
          src="/hdil/pm/pm1.png"
          class="rounded-xl shadow"
          v-if="!activeItem"
        />
        <img
          src="/hdil/pm/pm1.png"
          class="rounded-xl shadow"
          v-if="activeItem === 1"
        />
        <img
          src="/hdil/pm/pm2.png"
          class="rounded-xl shadow"
          v-if="activeItem === 2"
        />
        <img
          src="/hdil/pm/pm3.png"
          class="rounded-xl shadow"
          v-if="activeItem === 3"
        />
        <img
          src="/hdil/pm/pm4.png"
          class="rounded-xl shadow"
          v-if="activeItem === 4"
        />
        <img
          src="/hdil/pm/pm5.png"
          class="rounded-xl shadow"
          v-if="activeItem === 5"
        />
        <img
          src="/hdil/pm/pm6.png"
          class="rounded-xl shadow"
          v-if="activeItem === 6"
        />
        <img
          src="/hdil/pm/pm7.png"
          class="rounded-xl shadow"
          v-if="activeItem === 7"
        />
        <img
          src="/hdil/pm/pm8.png"
          class="rounded-xl shadow"
          v-if="activeItem === 8"
        />
      </div>
      <div class="w-3/12 px-8 mobile:px-0 items-center flex mobile:w-full">
        <div class="hdil-list">
          <hdil-card
            @activateItem="activateItem(5)"
            header="Lisa pilte ja faile"
            text="Kui tekib vajadus lisada projektiga seotud pilte või faile, siis saad seda teha ühe klikiga otse projekti alla."
            icon="/icons/hdil/pm-addfiles.svg"
            :active="activeView === 1 && activeItem === 5"
          ></hdil-card>
          <hdil-card
            @activateItem="activateItem(6)"
            header="Märkmik ja dokumendid"
            text="Halda kõiki ettevõtte dokumente ühest kohast. Lisa endale märkmikusse tööga seotud märkuseid."
            icon="/icons/hdil/pm-notedoc.svg"
            :active="activeView === 1 && activeItem === 6"
          ></hdil-card>
          <hdil-card
            @activateItem="activateItem(7)"
            header="Suhtle meeskonna ja klientidega"
            text="Saada mugavalt projektiga seotud infot, pilte ja faile klientidele, töötajatele ja projektijuhtidele."
            icon="/icons/hdil/pm-comm.svg"
            :active="activeView === 1 && activeItem === 7"
          ></hdil-card>
          <hdil-card
            @activateItem="activateItem(8)"
            header="Määra õiguseid"
            text="Hoia silma peal kes mida projekti all näeb. Piira või anna õiguseid vastavalt soovile. "
            icon="/icons/hdil/pm-perm.svg"
            :active="activeView === 1 && activeItem === 8"
          ></hdil-card>
        </div>
      </div>
    </div>
    <div class="flex px-12 mobile:flex-col mobile:px-4" v-if="activeView === 2">
      <div class="w-3/12 px-8 mobile:px-0 items-center flex mobile:w-full">
        <div class="hdil-list">
          <hdil-card
            @activateItem="activateItem(1)"
            header="Määra ülesandeid"
            text="Määra töötajatele temaga seotud ülesandeid. Ülesandele võib määrata kas ühe või mitu töötajat."
            icon="/icons/hdil/tm-task.svg"
            :active="activeView === 2 && activeItem === 1"
          ></hdil-card>
          <hdil-card
            @activateItem="activateItem(2)"
            header="Mõõda tööaega"
            text="Iga töötaja saab oma tööaja käima, pausile ja kinni panna ülesandepõhiselt."
            icon="/icons/hdil/tm-time.svg"
            :active="activeView === 2 && activeItem === 2"
          ></hdil-card>
          <hdil-card
            @activateItem="activateItem(3)"
            header="Jälgi töötunde"
            text="Saad jälgida iga töötaja töötunde ja saadavat töötasu sobiva perioodi jooksul."
            icon="/icons/hdil/tm-time2.svg"
            :active="activeView === 2 && activeItem === 3"
          ></hdil-card>
          <hdil-card
            @activateItem="activateItem(4)"
            header="Halda töötajaid"
            text="Lisa, kustuta ja muuda töötajaid ja nende informatsiooni vastavalt vajadusele. "
            icon="/icons/hdil/tm-manage.svg"
            :active="activeView === 2 && activeItem === 4"
          ></hdil-card>
        </div>
      </div>
      <div
        class="w-6/12 justify-center flex px-16 mobile:hidden medium:px-0 hdil-feature-img-container"
      >
        <img
          src="/hdil/tm/tm1.png"
          class="rounded-xl shadow"
          v-if="!activeItem"
        />
        <img
          src="/hdil/tm/tm1.png"
          class="rounded-xl shadow"
          v-if="activeItem === 1"
        />
        <img
          src="/hdil/tm/tm2.png"
          class="rounded-xl shadow"
          v-if="activeItem === 2"
        />
        <img
          src="/hdil/tm/tm3.png"
          class="rounded-xl shadow"
          v-if="activeItem === 3"
        />
        <img
          src="/hdil/tm/tm4.png"
          class="rounded-xl shadow"
          v-if="activeItem === 4"
        />
        <img
          src="/hdil/tm/tm5.png"
          class="rounded-xl shadow"
          v-if="activeItem === 5"
        />
        <img
          src="/hdil/tm/tm6.png"
          class="rounded-xl shadow"
          v-if="activeItem === 6"
        />
      </div>
      <div class="w-3/12 px-8 mobile:px-0 items-center flex mobile:w-full">
        <div class="hdil-list">
          <hdil-card
            @activateItem="activateItem(5)"
            header="Igal töötajal oma ligipääs"
            text="Iga töötaja saab oma profiiliga seotud ligipääsu ning näeb vaid talle määratud andmeid."
            icon="/icons/hdil/tm-access.svg"
            :active="activeView === 2 && activeItem === 5"
          ></hdil-card>
          <hdil-card
            @activateItem="activateItem(6)"
            header="Lisa faile ja informatsiooni."
            text="Saad lisada töötaja profiilile faile (tööleping, sertifikaat) ning töötajaga seotud infot."
            icon="/icons/hdil/tm-files.svg"
            :active="activeView === 2 && activeItem === 6"
          ></hdil-card>
        </div>
      </div>
    </div>
    <div class="flex px-12 mobile:px-4 mobile:flex-col" v-if="activeView === 3">
      <div
        class="w-3/12 px-8 mobile:px-0 items-center flex mobile:w-full mobile:flex-col"
      >
        <div class="hdil-list">
          <hdil-card
            @activateItem="activateItem(1)"
            header="Tee pakkumisi"
            text="Loo pakkumine otse Gewodos ja saada see kliendile välja. See jääb sulle alatiseks projekti juurde alles."
            icon="/icons/hdil/fm-offer.svg"
            :active="activeView === 3 && activeItem === 1"
          ></hdil-card>
          <hdil-card
            @activateItem="activateItem(2)"
            header="Tee arveid"
            text="Koosta arve otse pakkumise põhjal või loo uus arve. Saada arve otse Gewodost välja. See jääb sulle alatiseks projekti juurde alles."
            icon="/icons/hdil/fm-invoice.svg"
            :active="activeView === 3 && activeItem === 2"
          ></hdil-card>
          <hdil-card
            @activateItem="activateItem(3)"
            header="Järgi staatuseid"
            text="Vaata millised pakkumised või arved on vastu võetud, millised tagasilükatud ja millised ootel."
            icon="/icons/hdil/fm-status.svg"
            :active="activeView === 3 && activeItem === 3"
          ></hdil-card>
          <hdil-card
            @activateItem="activateItem(4)"
            header="Anna kliendile ligipääs"
            text="Klient saab mugavalt jälgida otse Gewodost oma projektiga seotud arveid ja pakkumisi."
            icon="/icons/hdil/fm-client.svg"
            :active="activeView === 3 && activeItem === 4"
          ></hdil-card>
        </div>
      </div>
      <div
        class="w-6/12 justify-center flex px-16 mobile:hidden medium:px-4 hdil-feature-img-container"
      >
        <img
          src="/hdil/invoicing/i1.png"
          class="rounded-xl shadow"
          v-if="!activeItem"
        />
        <img
          src="/hdil/invoicing/i1.png"
          class="rounded-xl shadow"
          v-if="activeItem === 1"
        />
        <img
          src="/hdil/invoicing/i2.png"
          class="rounded-xl shadow"
          v-if="activeItem === 2"
        />
        <img
          src="/hdil/invoicing/i3.png"
          class="rounded-xl shadow"
          v-if="activeItem === 3"
        />
        <img
          src="/hdil/invoicing/i4.png"
          class="rounded-xl shadow"
          v-if="activeItem === 4"
        />
        <img
          src="/hdil/invoicing/i5.png"
          class="rounded-xl shadow"
          v-if="activeItem === 5"
        />
        <img
          src="/hdil/invoicing/i6.png"
          class="rounded-xl shadow"
          v-if="activeItem === 6"
        />
      </div>
      <div class="w-3/12 px-8 mobile:px-0 items-center flex mobile:w-full">
        <div class="hdil-list">
          <hdil-card
            @activateItem="activateItem(5)"
            header="Jälgi arveid"
            text="Kõik sinu arved on ühes kohas ühiselt nähtavad. Filtreeri neid vastavalt vajadustele ja hoia neil silm peal."
            icon="/icons/hdil/fm-invoicestat.svg"
            :active="activeView === 3 && activeItem === 5"
          ></hdil-card>
          <hdil-card
            @activateItem="activateItem(6)"
            header="Jälgi pakkumisi"
            text="Kõik sinu pakkumised on ühes kohas ühiselt nähtavad. Filtreeri neid vastavalt vajadustele ja hoia neil silm peal."
            icon="/icons/hdil/fm-offerstat.svg"
            :active="activeView === 3 && activeItem === 6"
          ></hdil-card>
        </div>
      </div>
    </div>
    <div class="flex px-12 mobile:px-4 mobile:flex-col" v-if="activeView === 4">
      <div class="w-3/12 px-8 mobile:px-0 items-center flex mobile:w-full">
        <div class="hdil-list">
          <hdil-card
            @activateItem="activateItem(1)"
            header="Kliendiportaal"
            text="Gewodos on ülimugav ja ainulaadne kliendiportaal, kust Sinu klient näeb kõiki oma projekte, faile, pakkumisi ja arveid."
            icon="/icons/hdil/cm-portal.svg"
            :active="activeView === 4 && activeItem === 1"
          ></hdil-card>
          <hdil-card
            @activateItem="activateItem(2)"
            header="Klientide ülevaade"
            text="Hoia silm peal kõikidel oma klientidel ja nendega seotud informatsioonil ühes kohas."
            icon="/icons/hdil/cm-overview.svg"
            :active="activeView === 4 && activeItem === 2"
          ></hdil-card>
          <hdil-card
            @activateItem="activateItem(3)"
            header="Kliendiotsing"
            text="Otsi paari klikiga endale vajalikku klienti."
            icon="/icons/hdil/cm-search.svg"
            :active="activeView === 4 && activeItem === 3"
          ></hdil-card>
          <hdil-card
            @activateItem="activateItem(4)"
            header="Kliendi projektid"
            text="Näe kõiki kliendi aktiivseid ja lõppenud projekte ühest kohast. "
            icon="/icons/hdil/cm-projects.svg"
            :active="activeView === 4 && activeItem === 4"
          ></hdil-card>
        </div>
      </div>
      <div
        class="w-6/12 justify-center flex  px-16 mobile:hidden medium:px-4 hdil-feature-img-container"
      >
        <img
          src="/hdil/cm/cm1.png"
          class="rounded-xl shadow"
          v-if="!activeItem"
        />
        <img
          src="/hdil/cm/cm1.png"
          class="rounded-xl shadow"
          v-if="activeItem === 1"
        />
        <img
          src="/hdil/cm/cm2.png"
          class="rounded-xl shadow"
          v-if="activeItem === 2"
        />
        <img
          src="/hdil/cm/cm3.png"
          class="rounded-xl shadow"
          v-if="activeItem === 3"
        />
        <img
          src="/hdil/cm/cm4.png"
          class="rounded-xl shadow"
          v-if="activeItem === 4"
        />
        <img
          src="/hdil/cm/cm5.png"
          class="rounded-xl shadow"
          v-if="activeItem === 5"
        />
        <img
          src="/hdil/cm/cm6.png"
          class="rounded-xl shadow"
          v-if="activeItem === 6"
        />
      </div>
      <div class="w-3/12 px-8 mobile:px-0 items-center flex mobile:w-full">
        <div class="hdil-list">
          <hdil-card
            @activateItem="activateItem(5)"
            header="Arvete vaade"
            text="Vaata kliendiga seotud arveid ühes vaates."
            icon="/icons/hdil/cm-invoice.svg"
            :active="activeView === 4 && activeItem === 5"
          ></hdil-card>
          <hdil-card
            @activateItem="activateItem(6)"
            header="Pakkumiste vaade"
            text="Vaata kliendiga seotud pakkumisi ühes vaates."
            icon="/icons/hdil/cm-offer.svg"
            :active="activeView === 4 && activeItem === 6"
          ></hdil-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HdilCard from "@/components/public/hdil/HdilCard";
export default {
  components: {
    HdilCard
  },
  data() {
    return {
      activeView: 1,
      activeItem: null
    };
  },
  methods: {
    activateItem(item) {
      this.activeItem = item;
    },
    activateView(view) {
      this.activeItem = null;
      this.activeView = view;
    }
  }
};
</script>

<style lang="scss">
.hdil-section-title {
  @apply font-semibold text-lg;
}
.hdil-list {
  @apply flex flex-col w-full;
}
.hdil-feature-img-container {
  @apply items-center;
}
</style>
