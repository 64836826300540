<template>
  <div
    class="hdil-list-item"
    :class="active ? 'border-l-4 border-green' : ''"
    @click="activateItem"
    @mouseenter="activateItem"
  >
    <div class="hdil-item-image">
      <img :src="icon" :class="active ? 'filter-to-green' : ''" />
    </div>
    <div
      class="hdil-item-text"
      :class="active ? 'justify-center' : 'items-center'"
    >
      <span
        class="hdil-item-text-header"
        :class="active ? 'text-green' : 'my-auto'"
        >{{ header }}</span
      >
      <span class="hdil-item-text-body" v-if="active">{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    active: Boolean
  },
  methods: {
    activateItem() {
      this.$emit("activateItem");
    }
  }
};
</script>

<style lang="scss">
.hdil-list {
  .hdil-list-item {
    @apply flex bg-white py-3 px-2 mb-2 shadow rounded-xl cursor-pointer w-full duration-150;
    .hdil-item-image {
      @apply flex justify-center items-center w-12 h-12 self-center;
      object-fit: contain;
      img {
        @apply max-h-12;
      }
    }
    .hdil-item-text {
      @apply flex w-9/12 px-2 items-center flex-col;
      .hdil-item-text-header {
        @apply self-start font-medium;
      }
      .hdil-item-text-body {
        @apply text-sm;
      }
    }
  }
}
</style>
