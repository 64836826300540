<template>
  <div class="container-full flex-col">
    <product-header></product-header>
    <features></features>
    <easy-to-use></easy-to-use>
    <how-does-it-look></how-does-it-look>
    <partner-feedback></partner-feedback>
    <process></process>
    <dont-wait></dont-wait>
    <partners :white="true"></partners>
  </div>
</template>

<script>
import ProductHeader from "@/components/public/product/ProductHeader";
import Features from "@/components/public/product/Features";
import EasyToUse from "@/components/public/EasyToUse";
import PartnerFeedback from "@/components/public/PartnerFeedback";
import HowDoesItLook from "@/components/public/product/HowDoesItLook";
import DontWait from "@/components/public/DontWait";
import Partners from "@/components/public/landing/Partners";
import Process from "@/components/public/Process";
export default {
  components: {
    ProductHeader,
    Features,
    EasyToUse,
    PartnerFeedback,
    HowDoesItLook,
    DontWait,
    Partners,
    Process
  }
};
</script>
